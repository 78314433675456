import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  const style = {
    background_color: 'red',
    color: 'blue'
  }
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="192px" viewBox="0 0 1071.000000 423.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,423.000000) scale(0.100000,-0.100000)"
fill="#555bc4" fill-opacity="0.8" stroke="none">
<path d="M0 2115 l0 -2115 5355 0 5355 0 0 2115 0 2115 -5355 0 -5355 0 0
-2115z m2181 677 c53 -59 47 -161 -13 -205 -38 -29 -123 -33 -168 -10 -90 46
-90 192 1 241 15 8 48 11 88 9 57 -3 67 -7 92 -35z m1219 -672 l0 -660 -100 0
c-81 0 -100 3 -100 15 0 7 -5 37 -11 64 l-11 51 -32 -43 c-68 -89 -165 -127
-284 -110 -95 14 -169 53 -238 125 -91 95 -134 215 -134 371 1 209 95 384 247
455 52 24 70 27 163 27 117 0 163 -16 228 -78 l32 -31 0 237 0 237 120 0 120
0 0 -660z m580 404 l0 -257 41 47 c63 71 125 99 234 104 107 5 176 -16 243
-73 48 -42 97 -136 112 -214 5 -29 10 -193 10 -362 l0 -309 -115 0 -115 0 0
268 c0 147 -5 294 -10 327 -15 95 -64 144 -149 153 -103 10 -179 -43 -221
-155 -21 -53 -23 -80 -28 -325 l-5 -268 -113 0 -114 0 0 660 0 660 115 0 115
0 0 -256z m-2738 206 c114 -17 176 -45 244 -114 63 -63 96 -127 110 -216 l7
-40 -116 0 -115 0 -11 30 c-32 92 -94 129 -215 130 -61 0 -90 -5 -124 -21 -76
-37 -105 -118 -61 -173 27 -34 65 -53 256 -127 213 -82 267 -111 333 -178 71
-71 95 -137 88 -245 -13 -193 -144 -308 -386 -338 -105 -13 -233 -1 -312 29
-108 41 -204 125 -240 208 -22 51 -44 161 -35 175 3 6 54 10 113 10 l107 0 13
-52 c23 -97 98 -149 229 -156 105 -6 181 16 223 65 37 42 45 72 30 117 -18 55
-66 86 -242 155 -346 137 -420 195 -447 347 -18 96 10 199 70 264 99 106 289
158 481 130z m7753 -360 c85 -184 161 -348 168 -364 13 -28 17 -19 182 338
l169 366 158 0 158 0 0 -625 0 -625 -115 0 -115 0 0 448 0 447 -44 -95 c-24
-52 -100 -221 -171 -375 -70 -154 -132 -283 -139 -287 -14 -10 -128 -10 -142
0 -7 4 -54 102 -107 217 -180 397 -244 536 -251 542 -3 3 -6 -197 -6 -446 l0
-452 -117 3 -118 3 -3 610 c-1 336 0 616 3 623 3 10 43 12 170 10 l166 -3 154
-335z m-1487 153 l3 -123 124 0 125 0 0 -80 0 -80 -126 0 -125 0 3 -286 3
-286 28 -24 c33 -28 97 -32 171 -10 54 17 50 21 70 -82 l14 -72 -72 -22 c-47
-15 -96 -23 -146 -23 -134 0 -225 49 -274 147 l-26 52 0 303 0 303 -65 0 -65
0 0 80 0 80 65 0 65 0 0 118 c0 65 3 122 7 126 4 3 54 5 112 4 l106 -3 3 -122z
m-2000 -122 c55 -15 130 -68 169 -119 59 -78 63 -108 63 -484 l0 -339 -102 3
-103 3 -3 30 c-1 17 -5 45 -8 63 l-5 32 -32 -42 c-71 -93 -191 -132 -334 -110
-123 19 -202 73 -255 175 -29 54 -32 185 -6 233 43 79 113 124 231 148 95 20
157 20 272 1 50 -8 96 -15 104 -15 20 0 7 162 -17 202 -36 62 -147 87 -229 53
-52 -22 -71 -42 -83 -87 l-11 -38 -124 0 -125 0 0 39 c0 108 74 199 194 242
102 35 291 40 404 10z m1215 -1 c110 -41 184 -141 206 -280 6 -36 11 -199 11
-362 l0 -298 -115 0 -115 0 0 263 c0 144 -5 291 -10 326 -14 99 -62 150 -149
159 -105 10 -188 -51 -227 -165 -16 -50 -19 -92 -22 -320 l-3 -263 -115 0
-114 0 0 470 0 470 99 0 c111 0 102 7 117 -93 l7 -49 44 51 c43 49 96 84 153
102 52 15 177 10 233 -11z m-4533 -470 l0 -470 -115 0 -115 0 0 470 0 470 115
0 115 0 0 -470z m8117 -206 c50 -38 68 -71 68 -129 0 -58 -18 -91 -69 -129
-21 -16 -42 -21 -91 -21 -101 0 -158 54 -158 150 0 63 17 95 74 133 28 20 44
23 92 20 37 -3 67 -11 84 -24z"/>
<path d="M2870 2194 c-74 -32 -119 -96 -140 -198 -21 -105 13 -229 81 -291 49
-44 76 -55 140 -55 88 0 172 63 198 149 18 63 14 239 -8 286 -22 49 -65 93
-108 111 -42 18 -119 17 -163 -2z"/>
<path d="M5193 1841 c-114 -52 -86 -194 44 -221 133 -28 256 51 265 169 l3 45
-60 12 c-91 20 -202 17 -252 -5z"/>
</g>
</svg>
  );
}
